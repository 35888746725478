import React from "react"
import { LiteYouTubeEmbed } from "react-lite-youtube-embed"

const YouTube = ({ id, title }) => (
  <div>
    <LiteYouTubeEmbed id={id} title={title} noCookie={true} />
  </div>
)

export default YouTube
