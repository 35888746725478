import React from "react"
import styled from "styled-components"

import FixedHeroImage from "../../components/FixedHeroImage"
import { getImage } from "gatsby-plugin-image"

const CareerBanner = styled(FixedHeroImage)`
  height: 700px;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const FlyIn = styled.div`
  opacity: 1;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  transition: opacity 200ms ease 0s, transform 300ms ease 0s;
`

const HeroJob = ({ children, banner }) => {
  const backgroundFluidImageStack = [
    `linear-gradient(180deg,rgba(0,0,0,.4),rgba(0,0,0,.4))`,
    getImage(banner.childImageSharp.gatsbyImageData),
  ]
  return (
    <>
      <CareerBanner Tag="section" image={backgroundFluidImageStack}>
        <FlyIn>{children}</FlyIn>
      </CareerBanner>
    </>
  )
}

export default HeroJob
