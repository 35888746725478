import React from "react"
import styled from "styled-components"
import { rgba } from "polished"
import { Container, Row, Col } from "react-bootstrap"

import PageWrapper from "../PageWrapper"
import { Section, Title, Box, Badge } from "../Core"

import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt"

import PostDetails from "../../sections/blog/PostDetails"
import SEO from "../SEO"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import shortcodes from "../../components/MdxShortcodes"
import HeroJob from "../../sections/career/HeroJob"
import { Trans } from "@lingui/macro"
import { renderToStaticMarkup } from "react-dom/server"
import JsonLd from "../SEO/jsonld"
import CTA from "../../sections/career/CTA"

const Location = styled.span`
  color: #8c97ac;
  font-size: 16px;
  font-weight: 300;
  margin-right: 5px;
  display: flex;
  align-items: center;
`

const BadgeStyled = styled(Badge)`
  background-color: ${({ theme, bg }) => rgba(theme.colors[bg], 0.15)};
  color: ${({ theme, bg }) => theme.colors[bg]};
  border: none;
  font-weight: 300;
`

const JobLayout = ({ data: { localized, byId }, location }) => {
  const isLocalized = localized !== null
  const mdx = isLocalized ? localized : byId
  const content = (
    <MDXProvider components={shortcodes}>
      <MDXRenderer>{mdx.body}</MDXRenderer>
    </MDXProvider>
  )
  const contentString = renderToStaticMarkup(content)
  var validThrough = new Date() // Now
  validThrough.setDate(validThrough.getDate() + 30) // Set now + 30 days as the new date
  var employmentType = mdx.frontmatter.tags.map((tag) => {
    //"FULL_TIME", "PART_TIME", "CONTRACTOR", "TEMPORARY", "INTERN", "VOLUNTEER", "PER_DIEM", "OTHER"
    switch (tag) {
      case "Vollzeit":
      case "Full time":
        return "FULL_TIME"
      case "Werkstudent":
      case "Working Student":
        return "INTERN"
      default:
        return "OTHER"
    }
  })
  return (
    <>
      <SEO
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.description || mdx.excerpt || ""}
        image={
          mdx.frontmatter.banner
            ? mdx.frontmatter.banner.childImageSharp.gatsbyImageData.images
                .fallback.src
            : ""
        } // TODO: Why is banner null?
        pathname={location.pathname}
        article
      />
      <JsonLd
        json={{
          "@context": "https://schema.org/",
          "@type": "JobPosting",
          title: mdx.frontmatter.title,
          description: contentString,
          identifier: {
            "@type": "PropertyValue",
            name: "AMAI GmbH",
            value: "AMAI GmbH",
          },
          datePosted: new Date().toISOString().slice(0, 10),
          validThrough: validThrough,
          employmentType: employmentType,
          hiringOrganization: {
            "@type": "Organization",
            name: "AMAI GmbH",
            sameAs: "https://www.am.ai",
            logo: {
              "@type": "ImageObject",
              url: "https://www.am.ai/AMAI-OpenGraph.png",
            },
          },
          jobLocation: {
            "@type": "Place",
            address: {
              "@type": "PostalAddress",
              streetAddress: "Schwarzwaldstraße 39",
              postalCode: "76137",
              addressLocality: "Karlsruhe",
              addressRegion: "Baden-Württemberg",
              addressCountry: "DE",
            },
          },
          applicantLocationRequirements: {
            "@type": "Country",
            name: "Germany",
          },
          jobLocationType: "TELECOMMUTE",
        }}
      />
      <PageWrapper footerDark headerDark>
        <HeroJob banner={mdx.frontmatter.banner}>
          <Box>
            <Title
              className="text-center"
              variant="hero"
              css={{ color: "white" }}>
              {mdx.frontmatter.title}
            </Title>
          </Box>
        </HeroJob>
        <Section className="pb-0 pt-5">
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8" className="">
                <Box
                  mx="auto"
                  className="d-flex justify-content-between align-items-center">
                  <Location css={{ whiteSpace: "nowrap" }}>
                    <FaMapMarkerAlt css={{ marginRight: 5 }} />{" "}
                    {mdx.frontmatter.location}
                  </Location>
                  {mdx.frontmatter.tags.map((tag) => (
                    <BadgeStyled className="ml-2" bg="secondary" key={tag}>
                      {tag}
                    </BadgeStyled>
                  ))}
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="pb-0 pt-5">
          <Container>
            <Row className="justify-content-md-center">
              <Col lg="8" className="mb-5">
                <PostDetails>{content}</PostDetails>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="pb-0 pt-0">
          <Container>
            <Row className="justify-content-md-center">
              <Col lg="8" className="mb-5">
                <h3 style={{ fontSize: "1.65rem" }}>
                  <Trans>
                    Jetzt AI EXPERT werden und bewerben unter{" "}
                    <a href="mailto:career@am.ai">career@am.ai</a>
                  </Trans>
                </h3>
              </Col>
            </Row>
          </Container>
        </Section>
        <CTA></CTA>
      </PageWrapper>
    </>
  )
}

export const pageQuery = graphql`
  fragment jobQueryFragment on Mdx {
    id
    body
    excerpt(pruneLength: 200, truncate: true)
    timeToRead
    fields {
      slug
    }
    frontmatter {
      title
      tags
      date(formatString: "DD MMM, YYYY")
      author
      location
      banner {
        absolutePath
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
  query($locale: String!, $slug: String!, $id: String!) {
    localized: mdx(fields: { locale: { eq: $locale }, slug: { eq: $slug } }) {
      ...jobQueryFragment
    }
    byId: mdx(id: { eq: $id }) {
      ...jobQueryFragment
    }
  }
`

export default JobLayout
