import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

const JsonLd = ({ json }) => {
  return (
    <Helmet>
      {json && (
        <script type="application/ld+json">{JSON.stringify(json)}</script>
      )}
    </Helmet>
  )
}

export default JsonLd

JsonLd.propTypes = {
  json: PropTypes.any,
}

JsonLd.defaultProps = {
  json: null,
}
