import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"

import { Button, Section, Box, Text } from "../../components/Core"

import { Trans } from "@lingui/macro"

import { LocalizedLink } from "gatsby-theme-i18n"

import imgOval from "../../assets/image/svg/Net_Pfade_Light_reverse_v2 Kopie.svg"
import svgCurve from "../../assets/image/svg/l1-curve-cta.svg"

const LeftCard = styled(Box)`
  position: absolute;
  top: 0;
  left: 0px;
`

const RightCard = styled(Box)`
  position: absolute;
  top: 0;
  right: -275px;
`

const CTA = () => (
  <>
    {/* <!-- CTA section --> */}
    <Section bg="dark" className="position-relative">
      <LeftCard
        data-aos="fade-right"
        data-aos-duration="750"
        data-aos-once="true">
        <img src={imgOval} alt="" className="img-fluid" />
      </LeftCard>
      <RightCard>
        <img src={svgCurve} alt="" className="img-fluid" />
      </RightCard>
      <Container>
        <Row className="justify-content-center text-center">
          <Col lg="6">
            <Box mb={5} className="text-center">
              <Text color="light" opacity={0.9}>
                <Trans>
                  Schau Dir auch die anderen Positionen an, die wir gerade
                  besetzen und die Vorteile bei uns zu arbeiten.
                </Trans>
              </Text>
            </Box>
            <Box>
              <LocalizedLink to={`/career/`}>
                <Button>
                  <Trans>Weitere Stellen und Benefits</Trans>
                </Button>
              </LocalizedLink>
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
)

export default CTA
