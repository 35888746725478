import { MdxLink, LocalizedLink as Link } from "gatsby-theme-i18n"
import Newsletter from "../../sections/landing/Newsletter"
import YouTube from "../Video"



const shortcodes = {
  // Provide common components here
  a: MdxLink,
  newsletter: Newsletter,
  youtube: YouTube,
  link: Link,
}
export default shortcodes
